import $ from 'jquery';
import axios from 'axios';

const Social = {
    init() {
        this.getInstragamPosts();
    },

    async getInstragamPosts() {
        const { data } = await axios.get(`https://www.instagram.com/${process.env.MIX_INSTAGRAM_USERNAME}/?__a=1`);

        const imageData = data.graphql.user.edge_owner_to_timeline_media.edges;
        const imageUrls = imageData.map(image => image.node.display_url).slice(0, 4);
        const shortcodes = imageData.map(image => image.node.shortcode).slice(0, 4);

        $.each($('.js-social-image'), (i, image) => {
            const imageCaption = imageData[i].node.edge_media_to_caption.edges;

            if (imageCaption[0] != null) {
                image.setAttribute('alt', imageCaption[0].node.text);
            }

            image.setAttribute('data-src', imageUrls[i]);
            image.src = imageUrls[i];
        });

        $.each($('.js-social-link'), (i, link) => {
            link.href = `https://instagram.com/p/${shortcodes[i]}`;
        });
    },

};

export default Social;
