import $ from 'jquery';
import 'slick-carousel';

const Slider = {
    init() {
        if ($('.js-events-slider').length) {
            $('.js-events-slider').on('init reInit afterChange', (e, slick) => this.handlePaging(e, slick));
        }

        if ($('.js-slider').length) this.initSlider();
        if ($('.js-events-slider').length) this.initEventsSlider();
    },

    initSlider() {
        $('.js-slider').slick({
            nextArrow: $('.js-slider-next'),
            prevArrow: $('.js-slider-prev'),
            infinite: true,
            lazyLoad: 'anticipated',
            mobileFirst: true,
            rows: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 800,
            touchThreshold: 30,
        });
    },

    initEventsSlider() {
        $('.js-events-slider').slick({
            nextArrow: $('.js-slider-next'),
            prevArrow: $('.js-slider-prev'),
            infinite: false,
            lazyLoad: 'anticipated',
            mobileFirst: true,
            rows: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 800,
            touchThreshold: 30,
        });
    },

    handlePaging(e, slick) {
        const $slideDesc = $('.js-slide-desc');
        const $currSlide = $($('.js-event-slide')[slick.currentSlide]);
        const $currCount = $('.js-current-slide');
        const $totalSlides = $('.js-total-slides');
        const text = $currSlide.find('.js-event-slide-desc').text();
        const i = (slick.currentSlide || 0) + 1;

        $slideDesc.text(text);
        $currCount.text(i);
        $totalSlides.text(slick.slideCount);
    },
};

export default Slider;
