import Menu from './Menu';
import Modal from './Modal';
import Slider from './Slider';
import Social from './Social';

export default () => {
    Menu.init();
    Modal.init();
    Slider.init();
    Social.init();
};
