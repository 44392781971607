import $ from 'jquery';
import Globals from '../helpers/Globals';

const { $body, $window } = Globals;

const Header = {
    init() {
        $window.on('scroll', () => this.handleHeader());
        $body.on('click', '.js-menu-toggle', () => this.handleMenu());
    },

    handleHeader() {
        if (window.location.pathname !== '/') return;

        const $header = $('.header');

        if ($window.scrollTop() > 1) $header.removeClass('header-home');
        else $header.addClass('header-home');
    },

    handleMenu() {
        $body.toggleClass('menu-open');
    },
};

export default Header;
