import $ from 'jquery';
import Globals from '../helpers/Globals';

const { $body } = Globals;

const Modal = {
    init() {
        $body.on('click', '.js-open-modal-video', e => this.handleVideoModal(e));
        $body.on('click', '.js-modal-close', e => this.handleClose(e));
    },

    handleVideoModal() {
        const $videoIframe = $('.js-video-iframe');
        const videoUrl = $('.js-video-url').attr('data-video-url');

        $videoIframe.attr('src', '');
        $videoIframe.attr('src', `${videoUrl}?autoplay=1`);
        $body.addClass('modal-open');
    },

    handleClose() {
        $body.removeClass('modal-open');
        $('.js-video-iframe').attr('src', '');
    },
};

export default Modal;
